/* Globalne style */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  width: 100%;
  background: #333;
  padding: 20px 0;
  display: flex;
  justify-content: center; /* Wyśrodkowanie całej nawigacji */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px; /* Maksymalna szerokość kontenera */
}

.nav-left {
  display: flex;
  align-items: center;
  margin-right: auto; /* Umieszcza elementy po lewej stronie */
}

.nav-left .name {
  color: white;
  font-size: 28px;
  margin-right: 60px; /* Dodaje odstęp między nazwą a elementami menu */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 20px; /* Zmniejszono margines między elementami menu */
}

.App-header {
  background-color: #282c34;
  color: white;
  padding: 20px 40px;
}

.content {
  flex: 1; /* Zapewnia, że stopka jest na dole strony */
}

.footer {
  background-color: #333;
  color: #ccc;
  padding: 10px 0;
  text-align: center;
}

h1, h2, h3, p {
  margin: 20px 0;
  font-size: 34px; /* Ujednolicenie rozmiaru czcionki */
  line-height: 1.6;
}

a {
  color: #fff; /* Zmiana koloru linków na biały */
  text-decoration: none;
  font-size: 18px;
}

a:hover {
  text-decoration: underline;
}

.logo {
  width: 150px;
  margin: 20px auto;
}

.social-links a {
  display: inline-block;
  margin: 0 10px;
  font-size: 34px;
  color: white;
}

.social-links a:hover {
  color: #ccc; /* Kolor ikon po najechaniu */
}

.download-button {
  padding: 12px 24px;
  background-color: #FFFFFF; /* Zmieniony kolor przycisku */
  color: #000; /* Czarny tekst na przycisku */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; /* Zwiększony rozmiar tekstu na przycisku */
  transition: background-color 0.3s;
  margin-top: 20px; /* Dodanie odstępu nad przyciskiem */
}

.download-button:hover {
  background-color: #CCCCCC; /* Ciemniejszy odcień szarości przy najechaniu */
}
